import { useEffect } from 'react'

/**
 * @desc 팝업이 열릴 때 body 및 html 스크롤을 막습니다 훅
 * @param isLock 팝업이 열리는지 여부
 */
export const useBodyScrollHidden = (isLock: boolean) => {
  useEffect(() => {
    if (isLock) {
      document.documentElement.style.overflow = 'hidden'
      document.body.style.overflow = 'hidden'
      document.body.style.touchAction = 'none'
    } else {
      document.documentElement.style.overflow = ''
      document.body.style.overflow = ''
      document.body.style.touchAction = ''
    }
    return () => {
      document.documentElement.style.overflow = ''
      document.body.style.overflow = ''
      document.body.style.touchAction = ''
    }
  }, [isLock])
}
