import { FC, ReactNode } from 'react'
import {
  Modal as MuiModal,
  Fade,
  Backdrop,
  BackdropProps,
  Container,
} from '@material-ui/core'
import Portal from '@material-ui/core/Portal'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import styled, {
  createGlobalStyle,
  GlobalStyleComponent,
} from 'styled-components'
import {
  mapBulmaProps,
  BulmaProps,
  HTMLDivProps,
  HTMLButtonProps,
  HTMLDefaultProps,
} from './base'
import { ButtonOwnProps, mapButtonProps } from './button'

interface ModalProps extends HTMLDivProps, BulmaProps {
  active: boolean
}

export const Modal: FC<ModalProps> = ({ active, ...other }) => (
  <div
    {...mapBulmaProps(
      {
        ...other,
        className: classNames(other.className, {
          'is-active': active,
        }),
      },
      'modal',
    )}
  />
)

interface ModalBackgroundProps extends HTMLDivProps, BulmaProps {}

export const ModalBackground: FC<ModalBackgroundProps> = (props) => (
  <div {...mapBulmaProps(props, 'modal-background')} />
)

interface ModalContentProps extends HTMLDivProps, BulmaProps {}

export const ModalContent: FC<ModalContentProps> = (props) => {
  const { children, ...other } = mapBulmaProps(props, 'modal-content')

  return <div {...other}>{children}</div>
}

interface ModalCloseProps extends HTMLButtonProps, BulmaProps, ButtonOwnProps {
  onClick: () => void
}

export const ModalClose: FC<ModalCloseProps> = (props) => (
  <button {...mapBulmaProps(mapButtonProps(props), 'modal-close')} />
)

interface ModalCardProps extends HTMLDivProps, BulmaProps {}

export const ModalCard: FC<ModalCardProps> = (props) => (
  <div {...mapBulmaProps(props, 'modal-card')} />
)

interface ModalCardHeadProps extends HTMLDefaultProps, BulmaProps {}

export const ModalCardHead: FC<ModalCardHeadProps> = (props) => {
  const { children, ...other } = mapBulmaProps(props, 'modal-card-head')

  return <header {...other}>{children}</header>
}

interface ModalCardTitleProps extends HTMLDivProps, BulmaProps {}

export const ModalCardTitle: FC<ModalCardTitleProps> = (props) => {
  const { children, ...other } = mapBulmaProps(props, 'modal-card-title')

  return <p {...other}>{children}</p>
}

interface ModalCardBodyProps extends HTMLDefaultProps, BulmaProps {}

export const ModalCardBody: FC<ModalCardBodyProps> = (props) => {
  const { children, ...other } = mapBulmaProps(props, 'modal-card-body')

  return <section {...other}>{children}</section>
}

interface ModalCardFootProps extends HTMLDefaultProps, BulmaProps {}

export const ModalCardFoot: FC<ModalCardFootProps> = (props) => {
  const { children, ...other } = mapBulmaProps(props, 'modal-card-foot')

  return <footer {...other}>{children}</footer>
}

// TODO 타입 에러 제거
// @ts-ignore
const GlobalOverflowHiddenStyle: GlobalStyleComponent<
  object,
  object
> = createGlobalStyle`
  html {
    overflow: hidden;
  }
`

export interface InfoModalContainerProps {
  isOpen: boolean
  onRequestClose?: () => void
}

export const InfoModalContainer: FC<InfoModalContainerProps> = ({
  isOpen,
  onRequestClose,
  children,
}) => (
  <>
    {isOpen && (
      <Portal>
        <GlobalOverflowHiddenStyle />
        <InfoModalStyledWrapper active={isOpen}>
          <ModalBackground onClick={onRequestClose} />
          <ModalContent>{children}</ModalContent>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={onRequestClose}
          ></button>
        </InfoModalStyledWrapper>
      </Portal>
    )}
  </>
)
const InfoModalStyledWrapper = styled(Modal)`
  z-index: 200;
`

const WideInfoModalContent = styled((props) => <ModalContent {...props} />)`
  width: 768px !important;
  overflow: hidden;
`

export const WideInfoModalContainer: FC<InfoModalContainerProps> = ({
  isOpen,
  onRequestClose,
  children,
}) => (
  <>
    {isOpen && (
      <Portal>
        <GlobalOverflowHiddenStyle />
        <Modal active={isOpen}>
          <ModalBackground onClick={onRequestClose} />
          <WideInfoModalContent>{children}</WideInfoModalContent>
        </Modal>
      </Portal>
    )}
  </>
)

const useDeepDarkBackdropStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(10, 10, 10, 0.86)',
  },
})

const DeepDarkBackdrop: FC<BackdropProps> = (props) => {
  const classes = useDeepDarkBackdropStyles()
  return <Backdrop {...props} className={classes.root} />
}

const useBaseModalContainer = makeStyles({
  container: {
    position: 'absolute',
    left: '50%',
    bottom: '20px',
    width: '95%',
    borderRadius: '20px',
    transform: 'translate(-50%, -0%)',
    '&:focus': {
      outline: 'none',
    },
    backgroundColor: '#ffffff',
  },
})

interface BaseModalContainerProps {
  isOpen: boolean
  onModalClose?: () => void
  children?: ReactNode
}

export const BaseModalContainer: FC<BaseModalContainerProps> = ({
  isOpen,
  onModalClose,
  children,
}) => {
  const classes = useBaseModalContainer()
  return (
    <MuiModal
      open={isOpen}
      onClose={onModalClose}
      closeAfterTransition
      BackdropComponent={DeepDarkBackdrop}
    >
      <Fade in={isOpen}>
        <Container className={classes.container}>{children || ''}</Container>
      </Fade>
    </MuiModal>
  )
}
