import { useState, useCallback, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useLocalStorage } from 'react-use'
import { addDays } from 'date-fns'
import { useRouter } from 'next/router'
import { UAParser } from 'ua-parser-js'
import { SHOW_ALTERNATIVE_MODAL } from '@app.feature/ad/hooks/useFullScreenAdBanner'
import { IS_IOS_NATIVE } from 'constants/storeKeys'

const IS_IOS_NATIVE_COOKIE = 'lnkr_is_ios_native'
const IS_FIRST_PAGE_VISIT = 'lnkr_is_first_page_visit'
const useUpsellAppModal = () => {
  const router = useRouter()
  const [cookie, setCookie] = useCookies()
  const [isIOSNative] = useLocalStorage(IS_IOS_NATIVE, false)
  const isAndroidNative = (() => {
    if (typeof document === 'undefined') {
      return false
    }

    return document?.referrer?.includes('android-app://com.linkareer.linkareer')
  })()

  /**
   * 커뮤니티에 IOS Native 인지 판별하기 위해 쿠키를 심어야 한다.
   * 최대 30일씩 쿠키 갱신 , 메인 > 커뮤니티 순으로 진입하지 않을 시 IOS 에서도 앱모달이 뜰 수 있음
   */
  useEffect(() => {
    sessionStorage.setItem(IS_FIRST_PAGE_VISIT, 'true')
    if (!isIOSNative) return
    if (cookie?.[IS_IOS_NATIVE_COOKIE]) return

    const getCookieDomain = () => {
      const url = window.location.href
      if (url.includes('linkareer')) return '.linkareer.com'
      if (url.includes('lnkr')) return '.lnkr.cc'
      return 'localhost'
    }

    setCookie(IS_IOS_NATIVE_COOKIE, Date.now() % 1000, {
      expires: addDays(new Date(), 30),
      domain: getCookieDomain(),
      path: '/',
    })
  }, [])

  const [upsellAppModalIsOpen, setUpsellAppModalIsOpen] = useState(() => {
    let isMobileBrowser = false
    if (typeof navigator === 'undefined') {
      return isMobileBrowser
    }

    const parser = new UAParser(navigator.userAgent)
    const device = parser.getDevice().type || 'desktop'
    const isFirstVisit = sessionStorage.getItem(IS_FIRST_PAGE_VISIT)
    const isMainFullPopupViewed =
      sessionStorage.getItem(SHOW_ALTERNATIVE_MODAL) === 'true'

    if (device === 'mobile') {
      isMobileBrowser =
        !isIOSNative &&
        !isAndroidNative &&
        // https://stackoverflow.com/questions/21125337/how-to-detect-if-web-app-running-standalone-on-chrome-mobile
        !window.matchMedia('(display-mode: standalone)').matches &&
        // @ts-ignore
        !window.navigator?.standalone &&
        !window.flutter_inappwebview &&
        router.pathname === '/' &&
        !isFirstVisit &&
        isMainFullPopupViewed
    }

    return isMobileBrowser
  })

  const handleUpsellAppModalClose = useCallback(
    () => setUpsellAppModalIsOpen(false),
    [],
  )
  return {
    upsellAppModalIsOpen,
    handleUpsellAppModalClose,
  }
}

export default useUpsellAppModal
