import { useCookies } from 'react-cookie'
import { addDays, startOfDay } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { TIMEZONE } from 'utils/config'

export const FULL_BANNER_DISMISS_FOR_TODAY = 'full-banner-dismiss-for-today'

export const useFullScreenAdBannerCookies = () => {
  const [_, setCookies] = useCookies()

  const getCookieDomain = () => {
    const url = window.location.href

    if (url.includes('linkareer')) return '.linkareer.com'
    if (url.includes('lnkr')) return '.lnkr.cc'
    return 'localhost'
  }

  const setFullscreenAdDismissCookie = (expires: Date, domain: string) => {
    setCookies(FULL_BANNER_DISMISS_FOR_TODAY, Date.now(), {
      expires,
      domain,
      path: '/',
    })
  }

  const addDismissForTodayCookie = () => {
    const domain = getCookieDomain()
    const afterToday = zonedTimeToUtc(
      startOfDay(addDays(new Date(), 1)),
      TIMEZONE,
    )

    setFullscreenAdDismissCookie(afterToday, domain)
  }

  return { addDismissForTodayCookie }
}
