import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { FULL_BANNER_DISMISS_FOR_TODAY } from './useFullScreenAdBannerCookies'

export const FULL_BANNER_TEMPORARY_CLOSED = 'full-banner-temporary-closed'
export const SHOW_ALTERNATIVE_MODAL = 'show-alternative-modal'

export const useFullScreenAdBanner = () => {
  const [isOpenFullScreenAdBanner, setIsOpenFullScreenAdBanner] =
    useState<boolean>(false)
  const [cookies] = useCookies()

  useEffect(() => {
    const isModalTemporaryClosed = sessionStorage.getItem(
      FULL_BANNER_TEMPORARY_CLOSED,
    )
    const isDismissedForToday = !!cookies?.[FULL_BANNER_DISMISS_FOR_TODAY]

    if (isDismissedForToday) {
      sessionStorage.setItem(SHOW_ALTERNATIVE_MODAL, 'true')
    }

    if (!isModalTemporaryClosed && !isDismissedForToday) {
      setIsOpenFullScreenAdBanner(true)
    }
  }, [cookies])

  const addTemporaryCloseSessionStorage = () => {
    sessionStorage.setItem(FULL_BANNER_TEMPORARY_CLOSED, 'true')
  }

  const handleFullScreenAdBannerClose = () => {
    sessionStorage.setItem(SHOW_ALTERNATIVE_MODAL, 'true')
    setIsOpenFullScreenAdBanner(false)
  }

  return {
    isOpenFullScreenAdBanner,
    handleFullScreenAdBannerClose,
    addTemporaryCloseSessionStorage,
  }
}
